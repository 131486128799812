@use '../abstracts/variables' as *;
@use '../abstracts/mixins';
@use '../abstracts/functions';

.legacy {
  &.login-layout {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    min-height: 100vh;

    .login__main {
      padding: 3rem 0px;
      margin: auto;
      width: 100%;
      position: relative;
      border-radius: 0.5rem;

      .container {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    @include mixins.mdMinBreakPoint {
      flex-direction: row;
      align-items: stretch;
      border-radius: 0px;

      .login__main {
        margin-left: 0px;
        flex: 0 0 100%;
        width: 100%;
        min-height: 100%;
        max-width: none;
      }
    }

    /* FORM */
    .form-control {
      padding: 0.75rem 1.25rem 0.625rem;
      height: 3.125rem;
      border: 1px solid $color__secondary-light1;
      border-radius: 3.125rem;
      font-size: 0.875rem;
      color: $color__black;

      &::placeholder {
        color: $color__secondary;
      }
    }

    @media (max-height: 599px) {
      .login__main {
        width: 100%;
        flex: 0 0 100%;
      }
    }
  }

  .login__video {
    display: flex;
    justify-content: flex-end;
    position: relative;
    max-width: 580px;
    width: 87%;
  }

  .login__video1 {
    margin-top: 3vh;
    height: 33vh;
    z-index: 20;
  }

  .login__video2 {
    margin-top: 3vh;
    height: 32vh;
    z-index: 20;
    justify-content: flex-start;

    .login__video_player {
      transform: scaleX(-1);
    }
  }

  .login__video3 {
    height: 22vh;
    z-index: 20;
  }

  .login__video_player {
    mask-image: url('../../assets/new-ui/video-mask.svg');
    mask-position: center center;
    mask-repeat: no-repeat;
    mask-size: cover;
    max-height: 100%;

    &::-webkit-media-controls {
      display: none;
    }
  }
}
